import {
    AwesomeTableComponent,
    IColumnsProps,
    StringUtils,
} from "d-react-components";
import React, { useContext, useRef } from "react";
import Image from "../../components/Image";
import { AppStateContext } from "../../context/app";
import { IBooking } from "../../interface/booking";
import Messages from "../../languages/Messages";

const ProductItem = ({ product }: any) => (
    <div className="d-flex">
        <Image
            className="image-reg-small"
            src={product?.thumbnail?.thumbnail}
        />
        <div className="flex-column ml-3">
            <div>{product?.[Messages.getLanguage()]?.name}</div>
            <small>{`${Messages.sku}: ${product?.SKU}`}</small>
        </div>
    </div>
);

const BookingProductsDesktop = () => {
    const { bookingDetail } = useContext(AppStateContext);
    const { products, quantity, cost } = bookingDetail as IBooking;
    const tableRef = useRef<any>();
    const columns: IColumnsProps = [
        {
            title: Messages.itemName,
            dataIndex: "",
            render: (product) => <ProductItem product={product} />,
        },
        {
            title: Messages.unitPrice,
            dataIndex: "salePrice",
            render: StringUtils.moneyThaiFormat,
            align: "center",
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            align: "center",
        },
        {
            title: Messages.price,
            dataIndex: "",
            align: "right",
            render: (product) =>
                StringUtils.moneyThaiFormat(
                    product.salePrice * product.quantity
                ),
        },
    ];

    const renderSummaryRow = (title: any, content: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center border-bottom py-3 pr-2">
                <div>{title}</div>
                <div>{content}</div>
            </div>
        );
    };

    const renderOrderSummary = () => (
        <div className=" d-flex justify-content-end">
            <div className="w-50">
                {renderSummaryRow(Messages.quantity, quantity)}

                {renderSummaryRow(
                    <div className="text-bold">{Messages.total}</div>,
                    <div className="text-bold">
                        {StringUtils.moneyThaiFormat(cost)}
                    </div>
                )}
            </div>
        </div>
    );
    return (
        <div className="card-container mt-3 p-4 display-desktop-layout">
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                columns={columns}
                dataSource={products}
                bordered={false}
                pagination={false}
            />
            {renderOrderSummary()}
        </div>
    );
};

export default BookingProductsDesktop;
