import { ApolloLink } from "@apollo/client";
import Messages from "../languages/Messages";

export const AuthMiddleware = new ApolloLink((operation, forward) => {
    try {
        const accessToken = "";
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                Authorization: `Bearer ${accessToken}`,
            },
        }));
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log({ error });
    }

    return forward(operation);
});

export const LangMiddleware = new ApolloLink((operation, forward) => {
    try {
        const language = Messages.getLanguage();
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                language,
            },
        }));
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log({ error });
    }

    return forward(operation);
});
