import { Skeleton } from "antd";
import { Progress, UrlUtils } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import BookingAPI from "./api/booking/BookingAPI";
import { AppStateContext, appStateDefault, IAppState } from "./context/app";
import { IBooking } from "./interface/booking";
import App from "./screen/App";
import InitComponent from "./screen/InitComponent";

function Root() {
    const [bookingDetail, setBookingDetail] = useState<IBooking>();
    const [metaData, setMetaData] = useState<IAppState>(appStateDefault);

    useEffect(() => {
        loadMetaData();
        loadBookingDetail();
    }, []);

    const loadMetaData = () => {
        BookingAPI.loadMetaData().then(setMetaData);
    };

    const loadBookingDetail = () => {
        const { id } = UrlUtils.getQuery();
        if (isEmpty(id)) return;

        Progress.show(
            { method: BookingAPI.detail, params: [id] },
            setBookingDetail as any
        );
    };

    return (
        <AppStateContext.Provider
            value={
                {
                    ...metaData,
                    bookingDetail,
                    setBookingDetail,
                } as any
            }
        >
            <InitComponent />
            {bookingDetail && <App />}
            {!bookingDetail && <Skeleton active />}
        </AppStateContext.Provider>
    );
}

export default Root;
