import { StringUtils } from "d-react-components";
import { map } from "lodash";
import React, { useContext } from "react";
import Image from "../../components/Image";
import SectionHeader from "../../components/SectionHeader";
import { AppStateContext } from "../../context/app";
import { IBooking } from "../../interface/booking";
import Messages from "../../languages/Messages";

const ProductItem = ({ product }: any) => (
    <div className="d-flex align-items-center pb-3 border-bottom mt-3">
        <Image
            className="image-reg-small"
            src={product?.thumbnail?.thumbnail}
        />
        <div className="flex-column ml-3 w-100">
            <div>{product?.[Messages.getLanguage()]?.name}</div>
            <small>{`${Messages.sku}: ${product?.SKU}`}</small>
            <div className="d-flex justify-content-between w-100">
                <small>{`${Messages.quantity}: ${product?.quantity}`}</small>
                <small>{StringUtils.moneyThaiFormat(product?.salePrice)}</small>
            </div>
        </div>
    </div>
);

const BookingProductsMobile = () => {
    const { bookingDetail } = useContext(AppStateContext);
    const { products, quantity, cost } = bookingDetail as IBooking;

    const renderSummaryRow = (title: any, content: any) => (
        <div className="d-flex justify-content-between mt-1">
            <label>{title}</label>
            <text>{content}</text>
        </div>
    );

    const renderSummary = () => (
        <div>
            <SectionHeader label={Messages.summary} hiddenEdit />
            {renderSummaryRow(Messages.subTotal, quantity)}

            {renderSummaryRow(
                Messages.total,
                <div className="text-bold">
                    {StringUtils.moneyThaiFormat(cost)}
                </div>
            )}
        </div>
    );

    return (
        <div className="display-mobile-layout">
            <div className="card-container mt-3 p-4 flex-column">
                <SectionHeader label={Messages.items} hiddenEdit />
                {map(products, (product) => (
                    <ProductItem product={product} />
                ))}
            </div>
            <div className="card-container mt-3 p-4 ">{renderSummary()}</div>
        </div>
    );
};

export default BookingProductsMobile;
