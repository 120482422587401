import { gql } from "@apollo/client";
import { map } from "lodash";
import { appStateDefault, IAppState } from "../../context/app";
import { mapBookingFromSer } from "../../interface/booking";
import API from "../API";
import { F_BOOKING } from "./BookingFragment";

const detailConvertor = {
    fromServer: (res: any) => mapBookingFromSer(res?.data?.booking),
};

const metaDataConvertor = {
    fromServer: (res: any): IAppState => ({
        ...appStateDefault,
        provinceList: res?.data?.provinces ?? [],
        countryList: map(res?.data?.nationality ?? [], (item) => ({
            id: item,
            name: item,
        })),
    }),
};

export default {
    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_BOOKING}
                query booking($id: ID!) {
                    booking(id: $id) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { id },
        }),

    update: (id: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateBooking($id: ID!, $input: UpdateBookingInput) {
                    booking: updateBooking(id: $id, input: $input) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { id, input },
        }),

    updateSource: (bookingId: string, sourceBookingId: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateSource($bookingId: ID!, $sourceBookingId: ID!) {
                    booking: updateBookingSource(
                        bookingId: $bookingId
                        sourceBookingId: $sourceBookingId
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, sourceBookingId },
        }),

    updateServiceDate: (bookingId: string, serviceDate: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateServiceDate(
                    $bookingId: ID!
                    $serviceDate: Date
                ) {
                    booking: updateBookingServiceDate(
                        bookingId: $bookingId
                        serviceDate: $serviceDate
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, serviceDate },
        }),

    updateNote: (bookingId: string, note: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateNote($bookingId: ID!, $note: String) {
                    booking: updateBookingNote(
                        bookingId: $bookingId
                        note: $note
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, note },
        }),

    updateBilling: (bookingId: string, billing: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateBilling(
                    $bookingId: ID!
                    $billing: BillingBookingInput
                ) {
                    booking: updateBookingBilling(
                        bookingId: $bookingId
                        input: $billing
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, billing },
        }),

    createAddress: (bookingId: string, address: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation createAddress(
                    $bookingId: ID!
                    $address: AddressBookingInput
                ) {
                    booking: addBookingServiceAddress(
                        bookingId: $bookingId
                        input: $address
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, address },
        }),

    updateAddress: (bookingId: string, addressId: string, address: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateAddress(
                    $bookingId: ID!
                    $addressId: ID!
                    $address: UpdateAddressBookingInput
                ) {
                    booking: updateBookingServiceAddress(
                        bookingId: $bookingId
                        addressId: $addressId
                        input: $address
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, addressId, address },
        }),

    deleteAddress: (bookingId: string, addressId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation deleteAddress($bookingId: ID!, $addressId: ID!) {
                    booking: deleteBookingServiceAddress(
                        bookingId: $bookingId
                        addressId: $addressId
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, addressId },
        }),

    createScheduleService: (bookingId: string, scheduleServices: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation createScheduleServices(
                    $bookingId: ID!
                    $scheduleServices: [ScheduleServiceInput]
                ) {
                    booking: createScheduleServices(
                        bookingId: $bookingId
                        scheduleServices: $scheduleServices
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, scheduleServices: [scheduleServices] },
        }),

    updateScheduleService: (
        bookingId: string,
        scheduleServiceId: string,
        scheduleService: any
    ) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateScheduleService(
                    $bookingId: ID!
                    $scheduleServiceId: ID!
                    $scheduleService: ScheduleServiceInput
                ) {
                    booking: updateScheduleService(
                        bookingId: $bookingId
                        scheduleServiceId: $scheduleServiceId
                        scheduleService: $scheduleService
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, scheduleServiceId, scheduleService },
        }),

    deleteScheduleService: (bookingId: string, scheduleServiceId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation destroyScheduleService(
                    $bookingId: ID!
                    $scheduleServiceId: ID!
                ) {
                    booking: destroyScheduleService(
                        bookingId: $bookingId
                        scheduleServiceId: $scheduleServiceId
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, scheduleServiceId },
        }),

    delete: (id: string) => API.delete("destroyBooking", id),

    assignPIC: (bookingId: string, userId: string[]) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation assignPICBooking($bookingId: ID!, $userId: [ID]!) {
                    booking: assignPICBooking(
                        bookingId: $bookingId
                        userId: $userId
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, userId },
        }),

    removePIC: (bookingId: string, userId: string[]) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation removePICBooking($bookingId: ID!, $userId: [ID]!) {
                    booking: removePICBooking(
                        bookingId: $bookingId
                        userId: $userId
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, userId },
        }),

    updateContact: (bookingId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateBookingContact(
                    $bookingId: ID!
                    $input: BookingContactInput
                ) {
                    booking: updateBookingContact(
                        bookingId: $bookingId
                        input: $input
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, input },
        }),
    loadMetaData: () =>
        API.withConverter(metaDataConvertor).query({
            query: gql`
                query loadMetaData {
                    provinces {
                        id
                        name
                    }

                    nationality
                }
            `,
        }),
};
