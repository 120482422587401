import React from "react";
import { ICountry, IProvince } from "../interface/address";
import { IBooking } from "../interface/booking";
import { IPayment } from "../interface/payment";
import { IPaymentMethod } from "../interface/paymentMethod";

export interface IAppState {
    paymentMethods: IPaymentMethod[];
    provinceList: IProvince[];
    countryList: ICountry[];
    bookingDetail: IBooking;
    setBookingDetail: any;
}

export const appStateDefault: IAppState = {
    paymentMethods: [],
    countryList: [],
    provinceList: [],
    bookingDetail: {} as any,
    setBookingDetail: () => {},
};

export const AppStateContext = React.createContext(appStateDefault);

export interface IPaymentState {
    payment: IPayment;
}

export const paymentStateDefault: IPaymentState = {
    payment: {} as any,
};

export const PaymentDetailContext = React.createContext(paymentStateDefault);
