import {
    Button,
    Modal,
    Notifications,
    Progress,
    Select,
} from "d-react-components";
import { useFormik } from "formik";
import { findIndex, map } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import BookingAPI from "../../api/booking/BookingAPI";
import AddressFormInput from "../../components/AddressFormInput";
import InputTextForm from "../../components/InputTextForm";
import SectionHeader from "../../components/SectionHeader";
import { BOOKING_STATUS } from "../../constant/booking";
import { AppStateContext } from "../../context/app";
import {
    getFullAddress,
    IServiceAddress,
    mapAddressToSer,
} from "../../interface/address";
import Messages from "../../languages/Messages";
import { AddressServiceSchema } from "../../schema/address";

interface IAddressUpdateModal {
    open: boolean;
    onClose: () => void;
}

interface IAddressDetail {
    address: IServiceAddress;
    index: number;
}

const AddressUpdateModal = ({ open, onClose }: IAddressUpdateModal) => {
    const { bookingDetail, setBookingDetail } = useContext(AppStateContext);
    const { addresses = [], status } = bookingDetail ?? {};

    const defaultFormValue = {
        index: 1,
        ...(addresses[0] ?? {}),
    };
    const addressForm = useFormik<any>({
        initialValues: defaultFormValue as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AddressServiceSchema,
        onSubmit: (values: any) => {
            const input = mapAddressToSer(values);
            onUpdateShippingAddress(values?.id, input);
        },
    });

    const onUpdateShippingAddress = (addressId: string, input: any) => {
        Progress.show(
            {
                method: BookingAPI.updateAddress,
                params: [bookingDetail?.id, addressId, input],
            },
            (res) => {
                onClose();
                setBookingDetail(res);
                Notifications.showSuccess(
                    Messages.updateShippingAddressSuccess
                );
            }
        );
    };

    const onChangeAddress = (id: string) => {
        const index = findIndex(addresses, (item) => item.id === id);
        onUpdateAddress(addresses[index], index);
    };

    const onUpdateAddress = (shipping: IServiceAddress, index: number) => {
        addressForm.setValues({ ...shipping, index });
    };

    const classNameInput = "col-12 col-sm-6 mt-3";
    const formValues = addressForm.values;
    const formErrors = addressForm.errors;
    const inputProps = {
        className: classNameInput,
        form: addressForm,
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.updateShippingAddress}
            onSave={() => addressForm.handleSubmit()}
        >
            <div className="row">
                <Select
                    {...inputProps}
                    className="col-12 mt-3"
                    dataSource={addresses ?? []}
                    getLabel={getFullAddress}
                    value={formValues.id}
                    onChange={onChangeAddress}
                />
                <InputTextForm {...inputProps} keyData="firstName" />
                <InputTextForm {...inputProps} keyData="lastName" />
                <InputTextForm {...inputProps} keyData="nickName" />
                <InputTextForm {...inputProps} keyData="phone" />
                <AddressFormInput
                    formDataError={formErrors}
                    formDataValue={formValues}
                    onChange={(values: any) => {
                        addressForm.setValues({
                            ...addressForm.values,
                            ...values,
                        });
                    }}
                    show={{ country: false }}
                />
            </div>
        </Modal>
    );
};

const AddressDetail = ({ address, index }: IAddressDetail) => {
    const { bookingDetail } = useContext(AppStateContext);
    const { status } = bookingDetail ?? {};
    const { firstName, lastName, phone } = address;
    const [openEdit, setOpenEdit] = useState(false);
    const isHideEdit = useMemo(
        () => status !== BOOKING_STATUS.PENDING,
        [status]
    );

    const fullAddress = getFullAddress(address);

    return (
        <div className="flex-column mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <label>{`${Messages.address} ${index}`}</label>
                <Button
                    onClick={() => setOpenEdit(true)}
                    variant="trans"
                    hidden={isHideEdit}
                >
                    {Messages.edit}
                </Button>
            </div>
            <div>{`${firstName} ${lastName}`}</div>
            <div>{phone}</div>
            <div>{fullAddress}</div>
            {openEdit && (
                <AddressUpdateModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                />
            )}
        </div>
    );
};

const BookingAddress = () => {
    const { bookingDetail } = useContext(AppStateContext);
    const { addresses = [] } = bookingDetail ?? {};

    return (
        <div className="card-container p-4 mt-3">
            <SectionHeader label={Messages.shippingInformation} hiddenEdit />
            <div className="flex-column">
                {map(addresses, (address, index) => (
                    <AddressDetail address={address} index={index + 1} />
                ))}
            </div>
        </div>
    );
};

export default BookingAddress;
