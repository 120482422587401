import { join } from "lodash";

export interface ICountry {
    id: string;
    name: string;
}

export interface IProvince {
    id: string;
    name: string;
}

export interface IDistrict {
    provinceId: string;
    id: string;
    name: string;
    postcode?: string[];
}

export interface ISubdistrict {
    cityId: string;
    id: string;
    name: string;
    postcode?: string[];
}

export interface IAddress {
    id: string;
    firstName: string;
    lastName: string;
    fullName?: string;
    phone: string;
    country: ICountry;
    province: IProvince;
    district: IDistrict;
    subDistrict: ISubdistrict;
    postcode: number;
    address: string;
    typeOfPlace: string;
    fullAddress?: string;
    typeOfPlaceId?: string;
    pin: boolean;
}

export interface IServiceAddress extends IAddress {
    nickName: string;
    nameOfPlace?: string;
    remark?: string;
}

export interface IBillingAddress extends IAddress {
    email: string;
    citizenId: string;
    taxId: string;
    companyName: string;
    typeOfCustomer: string;
}

export interface IAddressFormShow {
    typeOfPlace?: boolean;
    remark?: boolean;
    map?: boolean;
    country?: boolean;
    address?: boolean;
}

export const getFullAddress = (address: any) => {
    if (!address) {
        return "";
    }
    const arrayAddress = [
        address.address,
        address?.subDistrict?.name,
        address?.district?.name,
        address?.province?.name,
        address?.postcode,
    ];
    return join(arrayAddress, ", ");
};

export const mapAddressToSer = (addressDefault: any) => {
    if (!addressDefault) {
        return {
            country: "Thai",
        };
    }

    const {
        id,
        fullName,
        fullAddress,
        index,
        pin,
        province,
        district,
        subDistrict,
        ...rest
    } = addressDefault;
    return {
        country: "Thai",
        province: province?.id,
        district: district?.id,
        subDistrict: subDistrict?.id,
        ...rest,
    };
};
