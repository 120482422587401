import classNames from "classnames";
import { TimeUtils } from "d-react-components";
import { find } from "lodash";
import React, { useContext } from "react";
import { BOOKING_STATUSES } from "../../constant/booking";

import { AppStateContext } from "../../context/app";
import Messages from "../../languages/Messages";

const GeneralInfo = ({ className, classNameRow }: any) => {
    const { bookingDetail } = useContext(AppStateContext);
    const { bookingNo, createdAt, serviceDate, status } = bookingDetail;
    const bookingStatus = find(BOOKING_STATUSES, (item) => item.id === status);
    const bookingStatusLabel = bookingStatus?.label ?? "pending";

    const classNameRowInfo = classNames("d-flex mt-1", classNameRow);
    const classNameContainer = classNames("flex-column flex-grow-1", className);

    const renderGeneralInfo = (title: string, content: any) => (
        <div className={classNameRowInfo}>
            <text className="font-weight-bold">{title}</text>
            <text className="ml-1">{content}</text>
        </div>
    );
    return (
        <div className={classNameContainer}>
            {renderGeneralInfo(Messages.bookingNo, `#${bookingNo}`)}
            {renderGeneralInfo(
                Messages.createdAt,
                TimeUtils.toDateTime(createdAt)
            )}
            {renderGeneralInfo(
                Messages.expireAt,
                TimeUtils.toDateTime(serviceDate)
            )}
            {renderGeneralInfo(
                Messages.bookingStatus,
                <div style={{ color: bookingStatus?.color }}>
                    {(Messages as any)[bookingStatusLabel]}
                </div>
            )}
        </div>
    );
};

const BookingGeneralInfo = () => {
    return (
        <>
            <div className="card-container p-4 mt-5 d-flex justify-content-between">
                <div className="flex-column flex-grow-1 justify-content-between">
                    <h4>De Hygienique (Thailand) Co.,Ltd</h4>
                    <div>298/64-65 Phitsanulok Soi 3, Dusit, Bangkok 10400</div>
                    <div>06-666-6666</div>
                    <div>www.dh-thailand.com</div>
                </div>
                <GeneralInfo
                    className="align-items-end display-desktop-layout "
                    classNameRow="justify-content-end"
                />
            </div>
            <div className="display-mobile-layout card-container p-4 mt-3">
                <GeneralInfo classNameRow="justify-content-between" />
            </div>
        </>
    );
};

export default BookingGeneralInfo;
