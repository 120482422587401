import { Button } from "d-react-components";
import React from "react";
import Messages from "../languages/Messages";

interface ISectionHeader {
    label: any;
    hiddenEdit?: boolean;
    onClickEdit?: () => any;
}

const SectionHeader = ({ label, hiddenEdit, onClickEdit }: ISectionHeader) => {
    return (
        <div className="d-flex justify-content-between align-items-center">
            <h5>{label}</h5>
            <Button variant="trans" onClick={onClickEdit} hidden={hiddenEdit}>
                {Messages.edit}
            </Button>
        </div>
    );
};

export default SectionHeader;
