import { map } from "lodash";
import Image from "../../components/Image";
import SectionHeader from "../../components/SectionHeader";
import { SUPPORTS } from "../../constant/app";
import Messages from "../../languages/Messages";

export const BookingSupport = () => {
    return (
        <div className="card-container mt-3 p-4">
            <SectionHeader label={Messages.support} hiddenEdit />
            <small>{Messages.supportDesc}</small>
            <div className="w-100 flex-center p-4">
                {map(SUPPORTS, (social) => (
                    <a href={social.url}>
                        <Image
                            src={social.icon}
                            className="image-square-small ml-5"
                        />
                    </a>
                ))}
            </div>
        </div>
    );
};
