import { gql } from "@apollo/client";
import { F_ADDRESS, F_BILLING } from "../address/AddressFragment";

export const F_BOOKING_PRODUCT = gql`
    fragment F_BOOKING_PRODUCT on Product {
        id
        th {
            name
        }
        en {
            name
        }
        productId
        thumbnail {
            thumbnail
        }
        regularPrice
        salePrice
        SKU
    }
`;

export const F_BOOKING = gql`
    ${F_BOOKING_PRODUCT}
    ${F_ADDRESS}
    ${F_BILLING}
    fragment F_BOOKING on Booking {
        id
        bookingNo

        cost
        quantity
        bookingService {
            product {
                ...F_BOOKING_PRODUCT
            }
            openingCost
            quantity
        }
        serviceDate
        notes
        source {
            id
            name
        }
        status

        createdAt

        addresses {
            ...F_ADDRESS
            remark
        }
        billing {
            ...F_BILLING
        }

        contact {
            firstName
            lastName
            email
            phone
        }
    }
`;
