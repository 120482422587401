import * as Yup from "yup";

export const ProDistrictSchema = Yup.object().shape({
    id: Yup.string().required("Required field!"),
});

export const AddressSchema = Yup.object().shape({
    firstName: Yup.string().required("Required field!"),
    lastName: Yup.string().required("Required field!"),
    phone: Yup.string()
        .min(10, "Phone number is too short!")
        .max(14, "Phone number is too long!")
        .required("Required field!"),

    // country: Yup.string().required("Required field!"),
    province: ProDistrictSchema,
    district: ProDistrictSchema,
    subDistrict: ProDistrictSchema,
    postcode: Yup.string().required("Required field!"),
    address: Yup.string().required("Required field!"),
});

export const AddressServiceSchema = Yup.lazy((value) => {
    return AddressSchema.concat(
        Yup.object().shape({
            typeOfPlace: Yup.string().required("Required field!"),
        }) as any
    );
});
