import { Modal, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React, { useContext, useMemo, useState } from "react";
import BookingAPI from "../../api/booking/BookingAPI";
import InputTextForm from "../../components/InputTextForm";
import SectionHeader from "../../components/SectionHeader";
import { BOOKING_STATUS } from "../../constant/booking";
import { AppStateContext } from "../../context/app";
import Messages from "../../languages/Messages";

interface IBookingContactUpdateModal {
    open: boolean;
    onClose: () => void;
}

const BookingContactUpdateModal = ({
    open,
    onClose,
}: IBookingContactUpdateModal) => {
    const { bookingDetail, setBookingDetail } = useContext(AppStateContext);
    const { contact } = bookingDetail ?? {};

    const contactValueDefault = {
        ...(contact ?? {}),
        confirmEmail: contact?.email,
        confirmPhone: contact?.phone,
    };

    const validateContact = (values: any) => {
        const { confirmEmail, email, confirmPhone, phone } = values;
        const errors = {} as any;
        if (confirmEmail !== email) {
            errors.confirmEmail = Messages.emailNotMatch;
        }
        if (confirmPhone !== phone) {
            errors.confirmPhone = Messages.phoneNotMatch;
        }

        return errors;
    };

    const contactForm = useFormik<any>({
        initialValues: contactValueDefault as any,
        validateOnChange: false,
        validateOnBlur: false,
        validate: validateContact,
        onSubmit: (values: any) => {
            onUpdateContact(values);
        },
    });

    const onUpdateContact = (input: any) => {
        Progress.show(
            {
                method: BookingAPI.updateContact,
                params: [bookingDetail?.id, input],
            },
            (res: any) => {
                Notifications.showSuccess(Messages.updateContactSuccess);
                setBookingDetail(res);
                onClose();
            }
        );
    };

    const classNameInput = "col-12 col-sm-6 mt-3";

    const renderInput = (keyData: string) => (
        <InputTextForm
            form={contactForm}
            className={classNameInput}
            keyData={keyData}
        />
    );
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.updateContact}
            onSave={() => contactForm.handleSubmit()}
        >
            <div className="row">
                {renderInput("firstName")}
                {renderInput("lastName")}
                {renderInput("email")}
                {renderInput("confirmEmail")}
                {renderInput("phone")}
                {renderInput("confirmPhone")}
            </div>
        </Modal>
    );
};

const BookingContact = () => {
    const { bookingDetail } = useContext(AppStateContext);
    const { status, contact } = bookingDetail ?? {};
    const [openEdit, setOpenEdit] = useState(false);

    const isHideEdit = useMemo(
        () => status !== BOOKING_STATUS.PENDING,
        [status]
    );

    return (
        <div className="card-container p-4 mt-3">
            <SectionHeader
                label={Messages.contact}
                hiddenEdit={isHideEdit}
                onClickEdit={() => setOpenEdit(true)}
            />
            <small>{Messages.bookingContactDesc}</small>
            <div className="mt-3 flex-column">
                <text>{`${contact?.firstName} ${contact?.lastName}`}</text>
                <text>{contact?.phone}</text>
                <text>{contact?.email}</text>
            </div>
            {openEdit && (
                <BookingContactUpdateModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                />
            )}
        </div>
    );
};

export default BookingContact;
